import { Box, ButtonBase, Stack, Typography } from "@mui/material"
import BasePageComponent from "../../components/base-page-component"
import { Add, ChevronLeft, ChevronRight } from "@mui/icons-material";
import ButtonComponent from "../../components/button-component"
import CardContainer from "../../components/card-container"
import { DateCalendar, DatePicker } from "@mui/x-date-pickers";
import { blueButtonColor, greenButtonColor, redButtonColor, yellowColor } from "../../styles/style-constants";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { count, equals, length, filter, map, reduce, values, compose, reject } from "ramda";
import MonthEventView from "./month-event-view";
import WeekEventView from "./week-event-view";
import DayEventView from "./day-event-view";
import { useIsAdmin } from "../../utils/user-hooks";
import { editEventRoute } from "../../utils/page-routes";
import { newFormEntityId, statusAccepted, statusAssigned, statusList, statusVacant } from "../../utils/constants";
import { useSelector } from "react-redux";
import { selectCurrentClubId } from "../../redux/selectors";
import { useApiUtilsContext } from "../../providers/api-utils-provider";
import { apiPathClubMembers, apiPathClubTeamsList, apiPathEventList, apiPathGetActivityTypes, apiPathGetEventTypes } from "../../utils/endpoint-paths";
import TableComponent, { columnTypeActivities, columnTypeStatus, columnTypeDate, columnTypeString } from "../../components/table-component";
import { formatTime, parseBackendDatetime } from "../../utils/helper-functions/datetime-functions";
import getHomeAwayName from "../../utils/helper-functions/get-home-away-name";
import { getEventStatus } from "../../utils/helper-functions/get-activity-status-counts";
import DropdownFilterField, { createFilterParams, filterAll } from "../../components/fields/dropdown-filter-field";
import { capitaliseFirstLetter } from "../../utils/helper-functions/capitalise-functions";
import isAllMembersTeam from "../../utils/helper-functions/is-all-members-team";
import SearchBarComponent from "../../components/search-bar-component";
import useDebounce from "../../utils/use-debounce";
import useDoNewTab from "../../utils/do-new-tab";

const viewMonth = 0
const viewWeek = 1
const viewDay = 2
const viewList = 3
const today = dayjs() 

const StatusCircle = ({ color, count, label }) => {
    return <Stack direction={"column"} alignItems={"center"} width={"100%"}>
        <Stack
            sx={{
                borderRadius: "100%",
                borderColor: color,
                border: "solid 3px",
                height: "75px",
                width: "75px",
                textAlign: "center",
                justifyContent: "center",
                fontWeight: 700,
                fontSize: "30px",
                color: color
            }}
        > {count}</Stack>
        <Typography color={color} textTransform={"uppercase"} fontWeight={700}>
            {label}
        </Typography>
    </Stack>

}


const ViewSelectButton = ({ view, label, setSelectedView, selectedView }) => {
    const isFirstButton = equals(view, viewMonth)
    const isLastButton = equals(view, viewList)
    const isSelected = equals(view, selectedView)

    return <div style={{
        height: '46px',
        background: isSelected ? '#aeb5bb' : 'transparent',
        borderTopLeftRadius: isFirstButton ? "5px" : "0px",
        borderBottomLeftRadius: isFirstButton ? '5px' : '0px',
        borderTopRightRadius: isLastButton ? '5px' : "0px",
        borderBottomRightRadius: isLastButton ? '5px' : '0px',
        paddingTop: '5px',
    }}>
        <ButtonBase onClick={() => setSelectedView(view)} sx={{
            borderTopLeftRadius: !isSelected && isFirstButton ? "5px" : "0px",
            borderBottomLeftRadius: !isSelected && isFirstButton ? '5px' : '0px',
            borderTopRightRadius: !isSelected && isLastButton ? '5px' : "0px",
            borderBottomRightRadius: !isSelected && isLastButton ? '5px' : '0px',
            fontFamily: "Montserrat",
            fontWeight: 500,
            backgroundColor: isSelected ? '#1a252f' : '#2c3e50',
            color: 'white',
            paddingX: 1,
            height: '36px',
            borderLeft: 'solid',
            borderRight: 'solid',
            borderColor: isSelected ? '#3c4d5d' : 'transparent',
            borderWidth: isSelected ? '5px' : '0px',

        }}>
            {label}
        </ButtonBase>
    </div>
}


const EventsPage = () => {

    const doNewTab = useDoNewTab();

    const { generateEndpoint, doGet } = useApiUtilsContext()
    const clubId = useSelector(selectCurrentClubId)
    const eventsListEndpoint = generateEndpoint(apiPathEventList)
    const getTeamsEndpoint = generateEndpoint(apiPathClubTeamsList(clubId))
    const getMembersEndpoint = generateEndpoint(apiPathClubMembers(clubId))
    const getEventTypesEndpoint = generateEndpoint(apiPathGetEventTypes(clubId))
    const getActivityTypesEndpoint = generateEndpoint(apiPathGetActivityTypes(clubId))

    const [selectedDate, setSelectedDate] = useState(today)
    const [selectedTab, setSelectedTab] = useState(0)
    const [selectedView, setSelectedView] = useState(viewMonth)

    const [events, setEvents] = useState([])

    const [displaySearch, setDisplaySearch] = useState("")
    const [searchValue, setSearchValue] = useState("");


    const [toDateFilter, setToDateFilter] = useState(null)
    const [fromDateFilter, setFromDateFilter] = useState(null)
    const [teamFilter, setTeamFilter] = useState(filterAll)
    const [memberFilter, setMemberFilter] = useState(filterAll)
    const [eventTypeFilter, setEventTypeFilter] = useState(filterAll)
    const [activityTypeFilter, setActivityTypeFilter] = useState(filterAll)
    const [statusFilter, setStatusFilter] = useState(filterAll)

    const [teamFilterList, setTeamFilterList] = useState([]);
    const [memberFilterList, setMemberFilterList] = useState([]);
    const [eventTypeFilterList, setEventTypeFilterList] = useState([]);
    const [activityTypeFilterList, setActivityTypeFilterList] = useState([]);

    const toDateFormatted = toDateFilter ? dayjs(toDateFilter).format("YYYY-MM-DD") : filterAll;
    const fromDateFormatted = fromDateFilter ? dayjs(fromDateFilter).subtract(1,'day').format("YYYY-MM-DD") : filterAll;

    useDebounce(() => {
        setSearchValue(displaySearch)
    }, 500, [displaySearch])


    const filters = {
        "search_text": searchValue,
        "to": toDateFormatted,
        "from": fromDateFormatted,
        "team": teamFilter,
        "person_id": memberFilter,
        "event_type": eventTypeFilter,
        "activity_type": activityTypeFilter,
        "status": statusFilter,
    };

    const filterParams = createFilterParams(filters)

    const allActivities = reduce((acc, event) => {
        acc = [...acc, ...event?.activities ?? []]
        return acc
    }, [])(events)



    const countEventStatus = (status) => count((event) => equals(getEventStatus(event), status))(events)
    const countActivityStatus = (status) => count((activity) => equals(activity?.status, status))(allActivities)


    const vacantCount = selectedTab === 0 ? countActivityStatus(statusVacant) : countEventStatus(statusVacant)
    const assignedCount = selectedTab === 0 ? countActivityStatus(statusAssigned) : countEventStatus(statusAssigned)
    const acceptedCount = selectedTab === 0 ? countActivityStatus(statusAccepted) : countEventStatus(statusAccepted)
    const totalCount = selectedTab === 0 ? length(allActivities) : length(events)


    const todaySelected = today.isSame(selectedDate, 'day')

    const isAdmin = useIsAdmin()

    const mapTableEvent = (event) => {
        const dayjs_date = parseBackendDatetime(event?.start)
        return {
            dayjs_date: dayjs_date,
            id: event?.id,
            time: formatTime(dayjs_date),
            type: event?.eventTypeName,
            eventName: event?.details?.name,
            homeOrAway: getHomeAwayName(event?.homeOrAway),
            activities: event?.activities,
            status: event?.activities,
            locationDetails: event?.eventLocation?.locationDetails,
            date: dayjs_date.format("YYYY-MM-DD"),
        }
    }

    const isFuture = (event) => {
        const comparisonDate = fromDateFilter === null ? selectedDate : fromDateFilter;
        return event.dayjs_date.isAfter(comparisonDate, 'day') || event.dayjs_date.isSame(comparisonDate, 'day');
    }

    const getDateHeader = () => {
 
        let out = ""
        let isCurrent = ""

        switch (selectedView) {
            case viewMonth:
                out = selectedDate.format('MMMM YYYY')
                isCurrent = today.isSame(selectedDate, 'month') ? '(This Month)' : ""
                break
            case viewWeek:
                const weekStart = selectedDate.startOf('week')
                const weekEnd = selectedDate.endOf('week')
                isCurrent = today.isSame(selectedDate, 'week') ? '(This Week)' : ""
                out = `${weekStart.format('MMM D')} - ${weekEnd.format('MMM D')} ${selectedDate.format('YYYY')}`
                break
            case viewDay:
                out = selectedDate.format("MMMM D, YYYY")
                isCurrent = today.isSame(selectedDate, 'day') ? '(Today)' : ""
                break
            default:
                out = "Events"
                break
        }
        if (isCurrent) {
            out = `${out} ${isCurrent}`
        }
        return out
    }

    const goBackOrForwardButton = (direction) => {
        let newDate = selectedDate
        switch (selectedView) {
            case viewMonth:
                newDate = newDate.add(direction, 'month')
                break
            case viewWeek:
                newDate = newDate.add(direction, 'week')
                break
            default:
                newDate = newDate.add(direction, 'day')
                break
        }
        setSelectedDate(newDate)
    }

    const selectToday = () => {
        setSelectedDate(today)
    }

    const onTabButtonClick = (tab) => {
        setSelectedTab(tab)
    }

    const newEvent = () => {
        doNewTab(editEventRoute, { target: newFormEntityId })
    }

    const tabButtonStyles = (tab) => {
        const isSelected = equals(tab, selectedTab)
        return {
            width: "100%",
            fontWeight: 600,
            fontFamily: "Montserrat",
            lineHeight: '15.85px',
            letterSpacing: '1.86px',
            paddingY: "10px",
            color: isSelected ? 'white' : '#323E59',
            backgroundColor: isSelected ? blueButtonColor : "#C4C4C4"
        }
    }

    const mapTeamList = (data) => compose(
        map(mapTeam),
        reject(isAllMembersTeam)
    )(data ?? [])

    const mapTeam = (data) => ({
        value: data?.id,
        label: data?.details?.name
    });
    const mapMember = (data) => ({
        value: data?.member?.id,
        label: data?.member?.full_name ?? data?.member?.email ?? ""
    });
    const mapEventType = (data) => ({
        value: data?.id,
        label: data?.details?.name
    });
    const mapActivityType = (data) => ({
        value: data?.id,
        label: data?.details?.name
    });

    useEffect(() => {
        const getTeams = async () => {
            if (clubId) {
                try {
                    const response = await doGet({ endpoint: getTeamsEndpoint })
                    setTeamFilterList(mapTeamList(response?.data))
                } catch (error) {
                    console.error(error)
                }

            }
        }

        const getMembers = async () => {
            if (clubId) {
                try {
                    const response = await doGet({ endpoint: getMembersEndpoint })
                    setMemberFilterList(map(mapMember)(response?.data ?? []))
                } catch (error) {
                    console.error(error)
                }
            }
        }

        const getEventTypes = async () => {
            if (clubId) {
                try {
                    const response = await doGet({ endpoint: getEventTypesEndpoint })
                    setEventTypeFilterList(map(mapEventType)(response?.data ?? []))
                } catch (error) {
                    console.error(error)
                }
            }
        }

        const getActivityTypes = async () => {
            if (clubId) {
                try {
                    const response = await doGet({ endpoint: getActivityTypesEndpoint })
                    setActivityTypeFilterList(map(mapActivityType)(response?.data ?? []))
                } catch (error) {
                    console.error(error)
                }
            }
        }

        getTeams();
        getMembers();
        getEventTypes();
        getActivityTypes();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clubId])

    useEffect(() => {

        const getEvents = async () => {
            const response = await doGet({ endpoint: eventsListEndpoint, queryParams: {...filterParams, "club_id":clubId }})
            setEvents(response?.data ?? [])
        }

        if (clubId) {
            getEvents()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clubId, doGet, ...values(filters)])

    const isCalenderViewType = equals(selectedView, viewMonth) || equals(selectedView, viewWeek) || equals(selectedView, viewDay)
    const mapped = map(mapTableEvent)(events)
    const tableMappedEvents = isCalenderViewType ? mapped : filter(isFuture)(mapped)

    return <BasePageComponent
        pageTitle={"Events"}
        width="100%"
        marginRight="50px"
        inlineJustify="flex-start"
        inlineContent={
            <Stack direction="row" justifyContent={"space-between"} width={"100%"}>
                <SearchBarComponent
                    placeholder="Search for Event Name..."
                    setSearchValue={setDisplaySearch}
                    searchValue={displaySearch}
                />
                <Stack direction="row">
                    <ViewSelectButton label="Month" view={viewMonth} selectedView={selectedView} setSelectedView={setSelectedView} />
                    <ViewSelectButton label="Week" view={viewWeek} selectedView={selectedView} setSelectedView={setSelectedView} />
                    <ViewSelectButton label="Day" view={viewDay} selectedView={selectedView} setSelectedView={setSelectedView} />
                    <ViewSelectButton label="List" view={viewList} selectedView={selectedView} setSelectedView={setSelectedView} />
                </Stack>
            </Stack>
        }
    >


        <Stack direction={"column"} marginBottom={5} marginRight={"50px"} width="full">

            <Stack direction={"row"} marginLeft="50px" marginTop="30px" columnGap={2} justifyContent={"space-between"}>
                <Stack direction={"row"} columnGap={2}>
                    <DatePicker
                        sx={{
                            width: "20ch",
                            '& .MuiInputBase-root': {
                                color: "rgb(0,0,0,0.65)",
                                background: "white"
                            },
                            '& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: `${blueButtonColor} !important`
                            },
                            '& fieldset': {
                                borderColor: `${blueButtonColor} !important`
                            }
                        }}
                        slotProps={{
                            actionBar: {
                                actions: ['clear']
                            },
                            textField: {
                                size: "small"
                            }
                        }}
                        label="Start Date"
                        format="DD/MM/YYYY"
                        value={fromDateFilter} 
                        onChange={(val) => setFromDateFilter(val)}
                    />
                    <DatePicker
                        slotProps={{
                            actionBar: {
                                actions: ['clear']
                            },
                            textField: {
                                size: "small"
                            }
                        }}
                        sx={{
                            width: "20ch",
                            '& .MuiInputBase-root': {
                                color: "rgb(0,0,0,0.65)",
                                background: "white"
                            },
                            '& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: `${blueButtonColor} !important`
                            },
                            '& fieldset': {
                                borderColor: blueButtonColor
                            }
                        }}
                        label="End Date"
                        format="DD/MM/YYYY"
                        value={toDateFilter}
                        onChange={(val) => setToDateFilter(val)}
                    />
                    <DropdownFilterField
                        placeholder={"Teams"}
                        value={teamFilter}
                        setValue={setTeamFilter}
                        options={teamFilterList}
                    />
                    <DropdownFilterField
                        placeholder={"Members"}
                        value={memberFilter}
                        setValue={setMemberFilter}
                        options={memberFilterList}
                    />
                    <DropdownFilterField
                        placeholder={"Event Types"}
                        value={eventTypeFilter}
                        setValue={setEventTypeFilter}
                        options={eventTypeFilterList}
                    />
                    <DropdownFilterField
                        placeholder={"Activity Types"}
                        value={activityTypeFilter}
                        setValue={setActivityTypeFilter}
                        options={activityTypeFilterList}
                    />
                    <DropdownFilterField
                        placeholder={"Statuses"}
                        options={statusList.map((s) => ({ label: capitaliseFirstLetter(s), value: s }))}
                        value={statusFilter}
                        setValue={setStatusFilter}
                    />
                </Stack>
                {isAdmin && <ButtonComponent title="Add New Event" icon={<Add />} onClick={newEvent} />}
            </Stack>

            {isCalenderViewType && <Stack direction="row" width="100%">

                <Stack direction="column">
                    <CardContainer padding="0" width="min-content">
                        <DateCalendar value={selectedDate} onChange={setSelectedDate} />
                    </CardContainer>

                    <CardContainer width="320px" padding="0" marginRight="0">

                        <Stack direction="row">
                            <ButtonBase
                                onClick={() => onTabButtonClick(0)}
                                sx={tabButtonStyles(0)}
                            >
                                ACTIVITIES
                            </ButtonBase>
                            <ButtonBase
                                onClick={() => onTabButtonClick(1)}
                                sx={tabButtonStyles(1)}
                            >
                                EVENTS
                            </ButtonBase>

                        </Stack>

                        <Stack direction={"column"} alignItems={"center"} spacing={1} paddingY={1}>
                            <StatusCircle color={redButtonColor} count={vacantCount} label={"Vacant"} />
                            <StatusCircle color={yellowColor} count={assignedCount} label={"Assigned"} />
                            <StatusCircle color={greenButtonColor} count={acceptedCount} label={"Accepted"} />
                            <StatusCircle color={"#323E59"} count={totalCount} label={selectedTab === 0 ? "Total Activities" : "Total Events"} />
                        </Stack>

                    </CardContainer>


                </Stack>


                <CardContainer width="100%" padding={0} marginRight={0} maxWidth="100% !important">
                    <Stack direction="column" width={"100%"} height={"100%"}>

                        <Stack direction="row" justifyContent={'space-between'} padding={1} width={"100%"}>

                            <Stack direction="row">
                                <ButtonBase
                                    onClick={() => goBackOrForwardButton(-1)}
                                    sx={{
                                        height: '36px',
                                        width: '36px',
                                        marginTop: '5px',
                                        backgroundColor: '#2c3e50',
                                        borderTopLeftRadius: "5px",
                                        borderBottomLeftRadius: "5px",
                                        color: 'white',
                                    }}>
                                    <ChevronLeft />
                                </ButtonBase>
                                <ButtonBase
                                    onClick={() => goBackOrForwardButton(1)}
                                    sx={{
                                        height: '36px',
                                        width: '36px',
                                        borderTopRightRadius: "5px",
                                        borderBottomRightRadius: "5px",
                                        marginTop: '5px',
                                        backgroundColor: '#2c3e50',
                                        color: 'white',
                                        marginRight: '5px'
                                    }}>
                                    <ChevronRight />
                                </ButtonBase>
                                <ButtonBase
                                    disabled={todaySelected}
                                    onClick={selectToday}
                                    sx={{
                                        fontFamily: "Montserrat",
                                        fontWeight: 500,
                                        backgroundColor: todaySelected ? '#76828e' : '#2c3e50',
                                        color: 'white',
                                        height: '36px',
                                        marginTop: '5px',
                                        paddingX: 1,
                                        borderRadius: "5px"
                                    }}>
                                    Today
                                </ButtonBase>
                            </Stack>

                            <Typography fontSize={'30px'}>
                                {getDateHeader()}
                            </Typography>


                        </Stack>

                        <Box marginTop="5px" />
                        
                        {equals(selectedView, viewMonth) && <MonthEventView events={events} selectedDate={selectedDate} onDayClick={(day) => {
                            setSelectedDate(day)
                            setSelectedView(viewDay)
                        }}
                            onDotClick={(day) => {
                                setSelectedDate(day)
                                setSelectedView(viewDay)
                            }}
                        />}
                        {equals(selectedView, viewWeek) && <WeekEventView events={events} selectedDate={selectedDate} onDayClick={(day) => {
                            setSelectedDate(day)
                            setSelectedView(viewDay)
                        }}
                            onDotClick={(day) => {
                                setSelectedDate(day)
                                setSelectedView(viewDay)
                            }}
                        />}
                        {equals(selectedView, viewDay) && <DayEventView events={events} selectedDate={selectedDate} />}

                    </Stack>
                </CardContainer>

            </Stack>}
            {!isCalenderViewType && <Box marginLeft="50px" marginTop={"40px"}><TableComponent
                items={tableMappedEvents ?? []}
                onRowClick={(item) => {
                    doNewTab(editEventRoute, {target: item?.id})
                }}
                groupBy={'date'}
                groupByColumnType={columnTypeDate}
                columns={[
                    { key: "time", title: "Time" },
                    { key: "status", title: "Status", type: columnTypeStatus },
                    { key: "eventName", title: "Event Name" },
                    { key: "type", title: "Type" },
                    { key: "homeOrAway", title: "H/A" },
                    { key: "locationDetails", title: "Location Details", type: columnTypeString },
                    { key: "activities", title: "Activities", type: columnTypeActivities }
                ]}

            /></Box>}

        </Stack>

    </BasePageComponent>

}

export default EventsPage