import { useEffect, useState } from "react"
import BasePageComponent from "../../components/base-page-component"
import PageActionsStack from "../../components/buttons/page-actions-stack"
import CardContainer from "../../components/card-container"
import { Grid, Stack, Tooltip, Typography } from "@mui/material"
import FormHeader from "../../components/form-header-component"
import { useIsAdmin } from "../../utils/user-hooks"
import DatePickerField from "../../components/fields/date-picker-field"
import FormField from "../../components/fields/form-field"
import BooleanField from "../../components/fields/boolean-field"
import HomeAwayField from "../../components/fields/home-away-field"
import TimeRangeField from "../../components/fields/time-range-field"
import { useApiUtilsContext } from "../../providers/api-utils-provider"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { selectCurrentClubId, selectMemberQuals, selectUserPersonId, selectUserUuid } from "../../redux/selectors"
import { useSelector } from "react-redux"
import DropdownField from "../../components/fields/dropdown-field"
import { apiPathAcceptDelegation, apiPathClubMembers, apiPathClubTeamsList, apiPathConfirmAttendee, apiPathCreateEvent, apiPathDeclineAttendee, apiPathDeclineDelegation, apiPathDeleteEvent, apiPathGetActivityTypes, apiPathGetClub, apiPathGetEvent, apiPathGetEventTypes, apiPathGetOpponentList, apiPathUnconfirmAttende, apiPathUpdateEvent, apiPathVolunteerActivity } from "../../utils/endpoint-paths"
import { all, assocPath, equals, find, findIndex, map, reject, update, pluck, includes } from "ramda"
import FormTableComponent from "../../components/form-table-component"
import { combineDateAndTime, dateFromDateTime, formatDateTimeForBackend, parseBackendDatetime, timeFromDateTime } from "../../utils/helper-functions/datetime-functions"
import GoogleMapsField from "../../components/fields/google-maps-field"
import { columnTypeActions, columnTypeAssign, columnTypeDropdown, columnTypeDuration, columnTypeFloat, columnTypeStatus } from "../../components/table-component"
import { useSearchParams } from "react-router-dom"
import { eventsRoute } from "../../utils/page-routes"
import { attendeeStatusUnconfirmed, newFormEntityId, statusAssigned, statusVacant } from "../../utils/constants"
import { isNotNullOrUndefinedOrEmpty, isNullOrUndefinedOrEmpty } from "../../utils/helper-functions/is-null-or-undefined-or-empty"
import FieldLabel from "../../components/fields/field-label"
import dayjs from "dayjs"
import useDoNavigate from "../../utils/do-navigate"
import ActivityActionsStack from "../../components/buttons/activity-actions-stack"
import { apiPathUnconfirmDelegation } from "../../utils/endpoint-paths"
import { NotificationDelegateDialog } from "../notification-page/notification-type-base"
import { EmailOutlined, PhoneOutlined } from "@mui/icons-material"
import CopyIconButton from "../../components/fields/copy-icon-button"
import ButtonComponent from "../../components/button-component"
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { darkBlueButtonColor, redButtonColor } from "../../styles/style-constants"
import BaseDialog from "../../components/dialog-component"
import CloseIcon from '@mui/icons-material/Close';
import { sortTeamMembersInclusive } from "../../utils/helper-functions/sort-team-members"

const EditEventPage = () => {

    const canEdit = useIsAdmin()
    const [searchParams, setSearchParams] = useSearchParams()
    const updateTarget = (id) => setSearchParams((prev) => ({...prev, target: id}))

    const doNavigate = useDoNavigate()

    const userUuid = useSelector(selectUserUuid);


    const { generateEndpoint, doGet, doPut, doPost, uploadPfp, doDelete } = useApiUtilsContext()

    // eslint-disable-next-line no-unused-vars

    const eventId = searchParams.get("target")
    const presetDate = searchParams.get("date") ?? ""

    const isCreatingNew = equals(eventId, newFormEntityId)

    const personId = useSelector(selectUserPersonId)
    const currentClubId = useSelector(selectCurrentClubId)
    const currentMemberQuals = useSelector(selectMemberQuals);


    const [loading, setLoading] = useState(false)
    const [name, setName] = useState()
    const [description, setDescription] = useState()
    const [date, setDate] = useState(isNotNullOrUndefinedOrEmpty(presetDate) ? dayjs(presetDate) : undefined)
    const [startTime, setStartTime] = useState()
    const [endTime, setEndTime] = useState()
    const [eventType, setEventType] = useState()
    const [team, setTeam] = useState()
    const [homeAway, setHomeAway] = useState()
    const [location, setLocation] = useState()
    const [pitch, setPitch] = useState()
    const [notifyMembers, setNotifyMembers] = useState(true)
    const [imageId, setImageId] = useState()
    const [imageFormData, setImageFormData] = useState()
    const [opponentId, setOpponentId] = useState()
    const [hasOpponent, setHasOpponent] = useState(true)
    const [event, setEventDetails] = useState({})
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);

    const [activities, setActivities] = useState([])
    const [teamMembers, setTeamMembers] = useState([])
    const [clubMembers, setClubMembers] = useState([])

    const [clubLocation, setClubLocation] = useState()

    const [teamList, setTeamList] = useState([])
    const [eventTypeList, setEventTypeList] = useState([])
    const [activityList, setActivityList] = useState([])
    const [opponentList, setOpponentList] = useState([])
    const [openDelegateDialog, setOpenDelegateDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const [isVolunteerLoading, setisVolunteerLoading] = useState(false);

    const teamsListEndpoint = generateEndpoint(apiPathClubTeamsList(currentClubId))
    const eventTypesEndpoint = generateEndpoint(apiPathGetEventTypes(currentClubId))
    const clubMembersEndpoint = generateEndpoint(apiPathClubMembers(currentClubId))
    const activityEndpoint = generateEndpoint(apiPathGetActivityTypes(currentClubId))
    const opponentEndpoint = generateEndpoint(apiPathGetOpponentList(currentClubId))

    const createEventEndpoint = generateEndpoint(apiPathCreateEvent(currentClubId))
    const getEventEndpoint = generateEndpoint(apiPathGetEvent(eventId))
    const updateEventEndpoint = generateEndpoint(apiPathUpdateEvent(eventId));

    const acceptEndpoint = generateEndpoint(apiPathConfirmAttendee(eventId, personId));
    const declineEndpoint = generateEndpoint(apiPathDeclineAttendee(eventId, personId));
    const unconfirmEndpoint = generateEndpoint(apiPathUnconfirmAttende(eventId, personId));
    const volunteerEndpoint = (activityId) => generateEndpoint(apiPathVolunteerActivity(activityId, personId));
    const declineDelegationEndpoint = (activityId) => generateEndpoint(apiPathDeclineDelegation(activityId));
    const acceptDelegationEndpoint = (activityId) => generateEndpoint(apiPathAcceptDelegation(activityId));
    const unconfirmDelegationEndpoint = (activityId) => generateEndpoint(apiPathUnconfirmDelegation(activityId)); 
    const deleteEventEndpoint = generateEndpoint(apiPathDeleteEvent(currentClubId, eventId))
    const allowDecline = canEdit || isCreatingNew || event?.allowDecline;

    const onVolunteer = async (row) => {
      try {
        setisVolunteerLoading(true);
        await doPut({ endpoint: volunteerEndpoint(row?.id) });
        setisVolunteerLoading(false);
        setActivities((prevActivities) => {
          return prevActivities.map((activity) => {
            if (activity.id === row?.id) {
              const clubMember = clubMembers.find(
                (member) => member.member.id === personId
              )?.member;
              return { ...activity, status: "ASSIGNED", clubMember };
            }
            return activity;
          });
        });
      } catch (error) {
        setisVolunteerLoading(false);
        console.error(error);
      }
    };

    const onDeclineDelegation = async (row, path) => {        
        try {
            await doPut({ endpoint: declineDelegationEndpoint( row?.id ) });
            onUpdateActivity(row, path, "VACANT")

        } catch (error) {
            console.error(error);
        }
    }

    const onUnconfirmDelegation = async (row, path) => {
      try {
        doPut({ endpoint: unconfirmDelegationEndpoint(row?.id) });
        onUpdateActivity(row, path, "ASSIGNED")
      } catch (error) {
        console.error(error);
      }
    };

    const onAcceptDelegation = async (row, path) => {
        try {
            await doPut({ endpoint: acceptDelegationEndpoint(row?.id) });
            onUpdateActivity(row, path, "ACCEPTED")
        } catch (error) {
            console.error(error);
        }
    }

    const onConfirm = async (statusOverride, memberId) => {
      if(statusOverride) { 
        setTeamMembers((prevTeamMembers) => {
          return prevTeamMembers.map((member) => {
            if (member.member.id === memberId) {
              return { ...member, status: "CONFIRMED" };
            }
            return member;
          });
        });
        return;
      }
      try {
        await doPut({ endpoint: acceptEndpoint });
        setTeamMembers((prevTeamMembers) => {
          return prevTeamMembers.map((member) => {
            if (member.member.uuid === userUuid) {
              return { ...member, status: "CONFIRMED" };
            }
            return member;
          });
        });
      } catch (error) {
        console.error(error);
      }
    };

    const onDecline = async (statusOverride, memberId) => {
      if(statusOverride) { 
        setTeamMembers((prevTeamMembers) => {
          return prevTeamMembers.map((member) => {
            if (member.member.id === memberId) {
              return { ...member, status: "DECLINED" };
            }
            return member;
          });
        });
        return;
      }
      try {
        await doPut({ endpoint: declineEndpoint });
        setTeamMembers((prevTeamMembers) => {
          return prevTeamMembers.map((member) => {
            if (member.member.uuid === userUuid) {
              return { ...member, status: "DECLINED" };
            }
            return member;
          });
        });
      } catch (error) {
        console.error(error);
      }
    };

    const onUnconfirm = async (statusOverride, memberId) => {
      if (statusOverride) {
        setTeamMembers((prevTeamMembers) => {
          return prevTeamMembers.map((member) => {
            if (member.member.id === memberId) {
              return { ...member, status: "UNCONFIRMED" };
            }
            return member;
          });
        });
        return;
      }
      try {
        await doPut({ endpoint: unconfirmEndpoint });
        setTeamMembers((prevTeamMembers) => {
          return prevTeamMembers.map((member) => {
            if (member.member.uuid === userUuid) {
              return { ...member, status: "UNCONFIRMED" };
            }
            return member;
          });
        });
      } catch (error) {
        console.error(error);
      }
    };
    
    const onDelegate = async (value, row, memberList) => {
      try {
        const newMember = memberList.find((id) => id?.id === value?.id);
        setActivities((prevActivities) => {
          return prevActivities.map((activity) => {
            if (activity.id === row?.id) {
              return {
                ...activity,
                status: "ASSIGNED",
                clubMember: newMember,
                delegator: activity?.clubMember,
              };
            }
            return activity;
          });
        });
      } catch (error) {
        console.error(error);
      }
    };

    const handleDelete = async () => {
      try {
        setIsDeleteLoading(true)
        await doDelete({ endpoint: deleteEventEndpoint })
        setIsDeleteLoading(false);
        doNavigate(eventsRoute);
      } catch (error) {
        console.error(error)
      }
    }
    
    const clubEdnpoint = generateEndpoint(apiPathGetClub(currentClubId))


    const calculateDuration = (start, end) => (date && start && end) && dayjs(combineDateAndTime(date, end)).diff(combineDateAndTime(date, start), "minutes")

    const totalDuration = calculateDuration(startTime, endTime)
    const hoursDuration = Math.floor(totalDuration / 60)
    const minutesDuration = totalDuration - (hoursDuration * 60)

    const onSetStartTime = (value) => {
        setStartTime(value)
        if (isNullOrUndefinedOrEmpty(endTime) || calculateDuration(dayjs(value), endTime) <= 0) {
            setEndTime(dayjs(value).add(1, "hour"))
        }
    }

    const onSetEndTime = (value) => {
        setEndTime(value)
        if (!isNullOrUndefinedOrEmpty(startTime) && calculateDuration(startTime, dayjs(value)) <= 0) {
            setStartTime(dayjs(value).add(-1, "hour"))
        }
    }

    const onSetOpponent = (value) => {
        if (equals(homeAway, "away")) {
            const opponent = getOpponentById(value)
            if (isNotNullOrUndefinedOrEmpty(opponent?.location)) {
                setLocation(opponent?.location)
                setPitch("")
            }
        }
        setOpponentId(value)
    }


    const onSetHomeAway = (value) => {
        if (equals(value, "home")) {
            if (isNotNullOrUndefinedOrEmpty(clubLocation)) {
                setLocation(clubLocation)
                setPitch("")
            }
        } else if (isNotNullOrUndefinedOrEmpty(opponentId)) {
            const opponent = getOpponentById(opponentId)
            if (isNotNullOrUndefinedOrEmpty(opponent?.location)) {
                setLocation(opponent?.location ?? location)
                setPitch("")
            }
        }

        setHomeAway(value)
    }

    const mandatoryValues = [
        location, pitch, name, description, date, startTime, endTime
    ]

    const canSubmit = all(isNotNullOrUndefinedOrEmpty)(mandatoryValues)

    const getById = list => id => find((item) => equals(item?.id)(id))(list)
    const getTeamById = getById(teamList)
    const getEventTypeById = getById(eventTypeList)
    const getActivityById = getById(activityList)
    const getClubMemberByUuid = uuid => find((item) => equals(item?.member?.uuid)(uuid))(clubMembers)
    const getTeamMemberIndex = uuid => findIndex((item) => equals(item?.member?.uuid)(uuid))(teamMembers)
    const getActivityIndex = id => findIndex((item) => equals(item?.id)(id) || equals(item?.index)(id))(activities)
    const getOpponentById = getById(opponentList)


    const mappedTeamList = map((team) => ({ value: team?.id, label: team?.details?.name }))(teamList)
    const mappedEventTypeList = map((event) => ({ value: event?.id, label: event?.details?.name }))(eventTypeList)
    const mappedActivities = map((activity) => ({ value: activity?.id, label: activity?.details?.name }))(activityList)
    const mappedClubMemberList = map((member) => ({
        value: member?.member?.uuid, label: member?.member?.fullName || member?.member?.email
    }))(clubMembers)

    const mappedOpponentList = map((opponent) => ({ value: opponent?.id, label: opponent?.details?.name, location: opponent?.location }))(opponentList)

    const mappedAttendees = map((teamMember) => {
        return {
            id: teamMember?.id,
            member: {
                id: teamMember?.member?.id
            },
            position: teamMember?.position,
            status: teamMember?.status
        }
    })(teamMembers)


    const mappedEventActivities = map((activity) => {
        return {
            status: activity?.status,
            id: activity?.id,
            type: { id: activity?.type?.id },
            details: activity?.type?.details,
            duration: Number.parseFloat(activity?.duration) ?? 0.0,
            multiplier: Number.parseFloat(activity?.multiplier) ?? 1.0,
            clubMember: activity?.clubMember,
            delegator: { id: personId }
        }
    })(activities)
    
    const onSubmit = async () => {

        setLoading(true)

        let pictureId = imageId
        if (imageFormData) {
            pictureId = await uploadPfp(imageFormData)
        }


        const body = {
            notifyMembers: notifyMembers,
            event: {
                type: eventType && {
                    id: eventType
                },
                team: team && {
                    id: team,
                },
                details: {
                    name,
                    description,
                    pictureId,
                },
                eventLocation: {
                    location: location,
                    locationDetails: pitch
                },
                opponent: opponentId && {
                    id: opponentId
                },
                attendees: mappedAttendees,
                homeOrAway: homeAway,
                start: formatDateTimeForBackend(combineDateAndTime(date, startTime)),
                endTime: formatDateTimeForBackend(combineDateAndTime(date, endTime)),
                activities: mappedEventActivities
            },

        }

        try {

            if (isCreatingNew) {

                const response = await doPost({ endpoint: createEventEndpoint, body })
                const newLocation = response?.headers?.location

                const newItemResponse = await doGet({ endpoint: newLocation })
                updateTarget(newItemResponse?.data?.id)
            } else {

                await doPut({ endpoint: updateEventEndpoint, body })

            }

        } catch (error) {
            console.error(error)
        }




        setLoading(false)
        doNavigate(eventsRoute)

    }

    const onCancel = async () => {
        if (!isCreatingNew) {
            await getEvent()
        }
        doNavigate(eventsRoute)
    }



    const onSetTeam = (value) => {
        const newTeam = getTeamById(value)

        setTeamMembers(map((member) => {
            if (isNullOrUndefinedOrEmpty(member?.position)) {
                return {...member, position: "Team Member"}
            } else {
                return member
            }
        })(newTeam?.members ?? []))

        setTeam(value)
    }

    const onSetEventType = (value) => {
      const newEventType = getEventTypeById(value);
      const activities = newEventType?.activities ?? [];
      const mappedActivities = activities.map((activity, index) => ({
          type: activity,
          multiplier: activity?.multiplier,
          duration: 0.0,
          index: index,
          status: "VACANT"
      }));
      setActivities(mappedActivities);
      setEventType(value);
  };
  

  const onAddActivitiy = (activity) => {
      const { value: activityId } = activity;
      const activityObject = getActivityById(activityId);
      setActivities((prev) => {
          const newIndex = prev.length;
          const newActivity = { index: newIndex, type: activityObject, multiplier: activityObject?.multiplier, duration: 0.0, status: "VACANT" };
          return [...prev, newActivity];
      });
  };


    const onRemoveActivity = (activity) => {
        const oldId = activity?.id
        const oldIndex = activity?.index
        if (isNotNullOrUndefinedOrEmpty(oldId)) {
            setActivities((prev) => reject((a) => equals(a?.id)(oldId))(prev))
        } else {
            setActivities((prev) => reject((a) => equals(a?.index)(oldIndex))(prev))
        }
    }

    const onAddTeamMember = (teamMember) => {
        const { value: memberUuid } = teamMember

        // An attendee object contains a "Person" object under "member" field
        // it is not a club member itself
        const member = getClubMemberByUuid(memberUuid)
        const newMember = {status: attendeeStatusUnconfirmed, member: member?.member, position: isNotNullOrUndefinedOrEmpty(member?.position) ? member?.position : "Team Member"}
        setTeamMembers((prev) => sortTeamMembersInclusive([...prev, newMember], userUuid))
    }

    const onRemoveTeamMember = (teamMember) => {
        const teamMemberUuid = teamMember?.member?.uuid
        setTeamMembers((prev) => reject((a) => equals(a?.member?.uuid)(teamMemberUuid))(prev))
    }

    const onUpdateTeamMember = (teamMember, path, value) => {
        const teamMemberIndex = getTeamMemberIndex(teamMember?.member?.uuid)
        setTeamMembers((prev) => update(teamMemberIndex, assocPath(path, value, teamMember))(prev))
    }

    const getActivityQualifications = (activity) => {
      return activity?.type?.requiredQualifications.filter(qual => qual?.needsCertification)
    }

    const checkMemberQuals = (activity) => {
      const memberQualIds = pluck('id', currentMemberQuals)
      const activityQuals = getActivityQualifications(activity);
      return all(activityQual => includes(activityQual.id, memberQualIds), activityQuals);
    }


    const onUpdateActivity = (activity, path, value) => {
        const activityIndex = getActivityIndex(activity?.id ?? activity?.index);
      
        if (path[0] === 'clubMember') {
          if (isNotNullOrUndefinedOrEmpty(value)) {
            activity.status = statusAssigned
          } else {
            activity.status = statusVacant;
          }
        }
        if(path[0] === 'status') {
          if(value === statusVacant) {
            activity.clubMember = null;
          }
        }
        setActivities((prev) => update(activityIndex, assocPath(path, value, activity))(prev))
    }

    const getEvent = async () => {
        const response = await doGet({ endpoint: getEventEndpoint })
        const responseData = response?.data ?? {}
        setEventDetails(responseData)
        setName(responseData?.details?.name)
        setDescription(responseData?.details?.description)

        const startDateTime = parseBackendDatetime(responseData?.start)
        const endDateTime = parseBackendDatetime(responseData?.endTime)

        setDate(dateFromDateTime(startDateTime))
        setStartTime(timeFromDateTime(startDateTime))
        setEndTime(timeFromDateTime(endDateTime))
        setEventType(responseData?.eventTypeId)
        setHomeAway(responseData?.homeOrAway)
        setTeam(responseData?.teamId)
        setTeamMembers(sortTeamMembersInclusive(responseData?.attendees, userUuid))
        setActivities(responseData?.activities ?? [])
        setLocation(responseData?.eventLocation?.location)
        setPitch(responseData?.eventLocation?.locationDetails)
        setImageId(responseData?.details?.pictureId)
        setOpponentId(responseData?.opponent?.id)
    }

    const getClubLocation = async () => {
        const response = await doGet({ endpoint: clubEdnpoint })
        setClubLocation(response?.data?.location)
    }

    useEffect(() => {

        const getTeamOptions = async () => {
            const response = await doGet({ endpoint: teamsListEndpoint })
            setTeamList(response?.data ?? [])
        }

        const getEventTypeOptions = async () => {
            const response = await doGet({ endpoint: eventTypesEndpoint })
            setEventTypeList(response?.data ?? [])
        }

        const getClubMemberOptions = async () => {
            const response = await doGet({ endpoint: clubMembersEndpoint })
            setClubMembers(response?.data ?? [])
        }

        const getActivityOptions = async () => {
            const response = await doGet({ endpoint: activityEndpoint })
            setActivityList(response?.data ?? [])
        }

        const getOpponentOptions = async () => {
            const response = await doGet({ endpoint: opponentEndpoint })
            setOpponentList(response?.data ?? [])
        }



        const getData = async () => {
            await Promise.all([
                getTeamOptions(),
                getEventTypeOptions(),
                getClubMemberOptions(),
                getActivityOptions(),
                getOpponentOptions(),
                getClubLocation()
            ])

            if (eventId && !isCreatingNew) {
                getEvent()
            }
        }

        if (currentClubId) {
            getData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentClubId, eventId])

    useEffect(() => {
        if(eventType) {
            const eventTypeObj = getEventTypeById(eventType)

            setHasOpponent(eventTypeObj?.hasOpponent ?? true)
        }
    }, [eventType, getEventTypeById])


    useEffect(() => {
        if (!eventId) {
            doNavigate("/")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventId])
    

    return (
      <BasePageComponent
        snackbarLoading={loading}
        pageTitle={
          canEdit ? (isCreatingNew ? "Create Event" : "Edit Event") : "Event"
        }
        marginRight="60px"
        backRoute={eventsRoute}
        inlineContent={
          canEdit && (
            <>
              <DeleteEventDialog
                openDeleteDialog={openDeleteDialog}
                setOpenDeleteDialog={setOpenDeleteDialog}
                handleDelete={handleDelete}
                isDeleteLoading={isDeleteLoading}
              />
              <PageActionsStack
                disableSave={!canSubmit}
                saveAction={onSubmit}
                cancelAction={onCancel}
                deleteComponent={
                  <ButtonComponent
                    title={"Delete Event"}
                    icon={<DeleteOutlinedIcon />}
                    background={darkBlueButtonColor}
                    onClick={() => setOpenDeleteDialog(true)}
                  />
                }
              />
            </>
          )
        }
      >
        <CardContainer padding="20px">
          <Stack spacing={5}>
            <FormHeader
              mandatory
              label="Event Name"
              title={name}
              setTitle={setName}
              description={description}
              setDescription={setDescription}
              readOnly={!canEdit}
              setFormData={setImageFormData}
              minioId={imageId}
              setMinioId={setImageId}
            />

            <Grid columns={5} container rowSpacing={5}>
              <Grid item xs={2}>
                <DatePickerField
                  minDate={dayjs()}
                  value={date}
                  setValue={setDate}
                  label={"Date"}
                  readOnly={!canEdit}
                  mandatory
                />
              </Grid>
              <Grid item xs={2}>
                <TimeRangeField
                  disabled={isNullOrUndefinedOrEmpty(date)}
                  startValue={startTime}
                  setStartValue={onSetStartTime}
                  endValue={endTime}
                  setEndValue={onSetEndTime}
                  readOnly={!canEdit}
                  mandatory
                />
              </Grid>
              {totalDuration && (
                <Grid item xs={1}>
                  <Stack spacing={2}>
                    <FieldLabel label={"Duration"} />
                    <Typography>
                      {hoursDuration} Hr {minutesDuration} Min
                    </Typography>
                  </Stack>
                </Grid>
              )}
              <Grid item xs={5}>
                <DropdownField
                  options={mappedEventTypeList}
                  label={"Event Type"}
                  value={eventType}
                  setValue={onSetEventType}
                  readOnly={!canEdit}
                />
              </Grid>

              <Grid item xs={2}>
                <DropdownField
                  options={mappedTeamList}
                  label={"Team"}
                  value={team}
                  setValue={onSetTeam}
                  readOnly={!canEdit}
                />
              </Grid>

              <Grid item xs={2}>
                {hasOpponent && (
                  <DropdownField
                    label={"Opponent"}
                    options={mappedOpponentList}
                    value={opponentId}
                    setValue={onSetOpponent}
                    readOnly={!canEdit}
                  />
                )}
              </Grid>

              <Grid item xs={5}>
                <HomeAwayField
                  value={homeAway}
                  setValue={onSetHomeAway}
                  readOnly={!canEdit}
                />
              </Grid>

              <Grid item xs={2}>
                <GoogleMapsField
                  label="Location"
                  value={location}
                  setValue={setLocation}
                  readOnly={!canEdit}
                  mandatory
                />
              </Grid>

              <Grid item xs={2}>
                <FormField
                  label="Location Details"
                  value={pitch}
                  setValue={setPitch}
                  readOnly={!canEdit}
                  mandatory
                />
              </Grid>
              {isCreatingNew && (
                <Grid item xs={5} width={"100%"}>
                  <BooleanField
                    label={"Notify members of event creation?"}
                    width={"100%"}
                    value={notifyMembers}
                    readOnly={!canEdit}
                    setValue={setNotifyMembers}
                  />
                </Grid>
              )}
            </Grid>

            <FormTableComponent
              title="Activities"
              items={activities}
              readOnly={!canEdit}
              onAdd={onAddActivitiy}
              onRemove={onRemoveActivity}
              onUpdate={onUpdateActivity}
              itemEquality={(searchItem, rowItem) => false}
              searchItems={mappedActivities}
              columnOpts={{ showStatusText: true, allowDecline: allowDecline }}
              columns={[
                {
                  title: "Name",
                  getValue: (row) => (
                    <Typography
                      noWrap
                      fontSize={"15px"}
                      fontWeight={"medium"}
                      color={"#323E59"}
                      textAlign={"initial"}
                    >
                      {row?.type?.details?.name}
                    </Typography>
                  ),
                  xs: 4.35,
                },
                {
                  title: "Status",
                  setPath: ["status"],
                  getValue: (row) => {
                    return {
                      status: row?.status ?? "VACANT",
                      canEdit: canEdit,
                      memberId: row?.clubMember?.id,
                      currentUser: userUuid,
                      rowUuid: row?.clubMember?.uuid,
                      onMemberUnconfirm: onUnconfirmDelegation,
                      onMemberAccept: onAcceptDelegation,
                      onMemberDecline: onDeclineDelegation,
                      onMemberDelegate: () => setOpenDelegateDialog(true),
                      decoration: (
                        <NotificationDelegateDialog
                          open={openDelegateDialog}
                          setOpen={setOpenDelegateDialog}
                          name={
                            row?.clubMember?.firstName || row?.clubMember?.email
                          }
                          activity={row}
                          event={event}
                          onDelegate={onDelegate}
                          updateStatus={true}
                          bodyText="We're sorry to hear that you can no longer attend the following Volunteer Event:"
                        />
                      ),
                    };
                  },
                  type: columnTypeStatus,
                  xs: 3.5,
                },
                {
                  title: (
                    <Stack direction={"row"}>
                      <Typography
                        letterSpacing={2}
                        fontSize={14}
                        textTransform={"uppercase"}
                        fontWeight={600}
                      >
                        Duration
                      </Typography>

                      <Tooltip
                        title="Duration for Activities, if not otherwise specified, is set automatically to the Duration of the Event itself following event creation."
                        sx={{
                          marginLeft: "15px",
                          marginTop: "-2.5px",
                        }}
                        arrow
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: "#323E59",
                              "& .MuiTooltip-arrow": {
                                color: "#323E59",
                              },
                              color: "#FFFFFF",
                            },
                          },
                        }}
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [0, -8],
                                },
                              },
                            ],
                          },
                        }}
                      >
                        <InfoOutlinedIcon
                          style={{
                            color: "#1890FF",
                          }}
                        />
                      </Tooltip>
                    </Stack>
                  ),
                  getValue: (row) => row?.duration ?? 0.0,
                  canEdit: true,
                  type: columnTypeDuration,
                  setPath: ["duration"],
                  xs: 3,
                },
                {
                  title: "",
                  getValue: (row) => row?.multiplier,
                  canEdit: true,
                  setPath: ["multiplier"],
                  type: columnTypeFloat,
                  xs: 3,
                },
                {
                  title: "Assignee",
                  getValue: (row) => {
                    return canEdit ? (
                      row?.clubMember
                    ) : (
                      <ActivityActionsStack
                        row={row}
                        onVolunteer={onVolunteer}
                        isVolunteerLoading={isVolunteerLoading}
                        userUuid={userUuid}
                        checkMemberQuals={checkMemberQuals}
                      />
                    );
                  },
                  options: map((clubMember) => clubMember?.member)(clubMembers),
                  canEdit: true,
                  type: !canEdit ? columnTypeAssign : columnTypeDropdown,
                  getOptionLabel: (item) => item?.fullName || item?.email,
                  setPath: ["clubMember"],
                  xs: 6,
                },
              ]}
            />

            <FormTableComponent
              title="Team Members"
              items={teamMembers}
              searchItems={mappedClubMemberList}
              readOnly={!canEdit}
              onAdd={onAddTeamMember}
              onRemove={onRemoveTeamMember}
              onUpdate={onUpdateTeamMember}
              columnOpts={{ showStatusText: true }}
              itemEquality={(searchItem, rowItem) => {
                return equals(searchItem?.value)(rowItem?.member?.uuid);
              }}
              columns={[
                {
                  title: "Name",
                  getValue: (row) => (
                    <Typography
                      noWrap
                      fontSize={"15px"}
                      fontWeight={"medium"}
                      color={"#323E59"}
                      textAlign={"initial"}
                    >
                      {row?.member?.fullName || row?.member?.email}
                    </Typography>
                  ),
                },
                {
                  title: "Status",
                  getValue: (row) => {
                    return {
                      status: row?.status ?? attendeeStatusUnconfirmed,
                      canEdit: canEdit,
                      currentUser: userUuid,
                      rowUuid: row?.member?.uuid,
                      onMemberConfirm: () =>
                        onConfirm(canEdit, row?.member?.id),
                      onMemberDecline: () =>
                        onDecline(canEdit, row?.member?.id),
                      onMemberUnconfirm: () =>
                        onUnconfirm(canEdit, row?.member?.id),
                    };
                  },
                  type: columnTypeStatus,
                },
                {
                  title: "Position",
                  getValue: (row) => row?.position,
                  canEdit: true,
                  setPath: ["position"],
                },
                {
                  title: "Actions",
                  getValue: (row) => (
                    <>
                      <CopyIconButton
                        value={row?.member?.email}
                        icon={<EmailOutlined />}
                      />
                      {row?.member?.mobile && (
                        <CopyIconButton
                          value={row?.member?.mobile}
                          icon={<PhoneOutlined />}
                        />
                      )}
                    </>
                  ),
                  type: columnTypeActions,
                },
              ]}
            />
          </Stack>
        </CardContainer>
      </BasePageComponent>
    );
}


export const DeleteEventDialog = ({
  openDeleteDialog,
  setOpenDeleteDialog,
  handleDelete,
  isDeleteLoading,
}) => {
  return (
    <BaseDialog
      open={openDeleteDialog}
      onClose={() => setOpenDeleteDialog(false)}
      title={"Delete Event?"}
      titleProps={{ textAlign: "center", fontSize: "24px" }}
      content={
        <Stack direction={"column"} alignContent={"center"} gap={"10px"}>
          <Typography
            color={"#3C3352"}
            letterSpacing={"2px"}
            fontSize={"14px"}
            fontWeight={"600"}
            textAlign={"center"}
          >
            Are you sure you want to delete?
          </Typography>
          <Typography
            color={"#3C3352"}
            letterSpacing={"2px"}
            fontSize={"14px"}
            fontWeight={"600"}
            textAlign={"center"}
          >
            (Please note, this action cannot be reversed)
          </Typography>
        </Stack>
      }
      actionProps={{ sx: { justifyContent: "center", marginBottom: "20px" } }}
      paperProps={{
        sx: { border: "1px solid rgba(50, 62, 89, 1)", paddingX: "30px" },
      }}
      actions={
        <Stack direction={"row"} gap={"25px"}>
          <ButtonComponent
            title={"Delete"}
            icon={<DeleteOutlinedIcon fontSize="small" />}
            background={darkBlueButtonColor}
            onClick={handleDelete}
            disabled={isDeleteLoading}
          />
          <ButtonComponent
            disabled={isDeleteLoading}
            title={"Cancel"}
            icon={<CloseIcon fontSize="small" />}
            background={redButtonColor}
            onClick={() => setOpenDeleteDialog(false)}
          />
        </Stack>
      }
    />
  );
};

export default EditEventPage