import { attendeeStatusConfirmed, 
            attendeeStatusDeclined, 
            attendeeStatusUnconfirmed, 
            statusAccepted, 
            statusAssigned,
            statusVacant, 
            statusActive, 
            statusInactive, 
            statusPending } from "../utils/constants"

export const primaryColor =  "#323e59"
export const backgroundColor = "#f0f0f0"
export const blueButtonColor = "#1890FF"
export const greenButtonColor = "#15AC1D"
export const redButtonColor = "#F41616"
export const darkBlueButtonColor = "#323E59"
export const greyTextColor = "#3C3352"
export const blackTextColor = "#020205"
export const yellowColor = "#FFAE35"
export const maxPageWidth = "937px"
export const drawerWidth = "293px"

export const fieldWidth = "30ch"


export const statusColors = {
    [statusAccepted]:greenButtonColor,
    [statusVacant]: redButtonColor,
    [statusAssigned]: yellowColor,
    [attendeeStatusConfirmed]:greenButtonColor,
    [attendeeStatusDeclined]: redButtonColor,
    [attendeeStatusUnconfirmed]: yellowColor,
    [statusActive]: greenButtonColor,
    [statusPending]: yellowColor,
    [statusInactive]: redButtonColor
}