import { Stack } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import ButtonComponent from "../../button-component";
import { blueButtonColor, greenButtonColor, redButtonColor } from "../../../styles/style-constants";

const SaveCancelAddStack = ({
        saveAction, 
        cancelAction, 
        addAction, 
        addLabel,
        disableSave=false,
        disableCancel=false,
        disableAdd=false
    }) => {
    return <Stack spacing={2} direction="row">
    <ButtonComponent disabled={disableSave} onClick={saveAction} title={"Save Changes"} icon={<AddIcon />} background={greenButtonColor}/>
    <ButtonComponent disabled={disableCancel} onClick={cancelAction} title={"Cancel"} icon={<CloseIcon />} background={redButtonColor}/>
    <ButtonComponent disabled={disableAdd} onClick={addAction} title={addLabel} icon={<AddIcon />} background={blueButtonColor} />
</Stack>
};

export default SaveCancelAddStack;