import { Grid } from "@mui/material"
import BasePageComponent from "../../components/base-page-component"
import ButtonStack from "../../components/buttons/save-cancel-stack"
import CardContainer from "../../components/card-container"
import FormHeader from "../../components/form-header-component"
import FormField from "../../components/fields/form-field"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { selectCurrentClubId } from "../../redux/selectors"
import GoogleMapsField from "../../components/fields/google-maps-field"
import { opponentsRoute } from "../../utils/page-routes"
import { useIsAdmin } from "../../utils/user-hooks"
import { useApiUtilsContext } from "../../providers/api-utils-provider"
import { apiPathGetOpponent, apiPathPostOpponent, apiPathUpdateOpponent } from "../../utils/endpoint-paths"
import useDoNavigate from "../../utils/do-navigate"
import { useSearchParams } from "react-router-dom"
import { equals } from "ramda"
import { newFormEntityId } from "../../utils/constants"

const EditOpponentPage = () => {


    
    const doNavigate = useDoNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const updateTarget = formEntityId => setSearchParams((prev) => ({...prev, target:formEntityId}))

    const opponentId = searchParams.get("target")
    const isCreatingNew = equals(opponentId, newFormEntityId)
    const clubId = useSelector(selectCurrentClubId)

    

    const { doGet, generateEndpoint, doPost, doPut, uploadPfp } = useApiUtilsContext()

    const createOpponentEndpoint = generateEndpoint(apiPathPostOpponent(clubId))
    const updateOpponentEndpoint = generateEndpoint(apiPathUpdateOpponent(opponentId))
    const getOpponentEndpoint = generateEndpoint(apiPathGetOpponent(opponentId))

    const canEdit = useIsAdmin()

    const [snackbarLoading, setSnackbarLoading] = useState(false)


    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [imageId, setImageId] = useState()
    const [imageFormData, setImageFormData] = useState()
    const [location, setLocation] = useState()
    const [clubContactNumber, setClubContactNumber] = useState("")
    const [clubContactEmail, setClubContactEmail] = useState("")
    const [contactPersonName, setContactPersonName] = useState("")
    const [contactPersonNumber, setContactPersonNumber] = useState("")
    const [contactPersonEmail, setContactPersonEmail] = useState("")


    const getData = async () => {
        try {
            const response = await doGet({ endpoint: getOpponentEndpoint })
            const data = response?.data ?? {}
            setName(data?.details?.name)
            setDescription(data?.details?.description)
            setImageId(data?.details?.pictureId)
            setLocation(data?.location)
            setClubContactNumber(data?.mobile)
            setClubContactEmail(data?.email)
            setContactPersonEmail(data?.mainContact?.email)
            setContactPersonName(data?.mainContact?.name)
            setContactPersonNumber(data?.mainContact?.mobile)

        } catch (error) {
            console.error(error)
        }
    }

    const onSubmit = async () => {
        setSnackbarLoading(true)
        try {
            let pictureId = imageId
            if (imageFormData) {
                pictureId = await uploadPfp(imageFormData)
            }
            const body = {
                details: {
                    pictureId,
                    name,
                    description
                },
                email: clubContactEmail,
                mobile: clubContactNumber,
                mainContact: {
                    name: contactPersonName,
                    mobile: contactPersonNumber,
                    email: contactPersonEmail
                },
                location: location ?? {}
            }

            if (isCreatingNew) {

                const response = await doPost({ endpoint: createOpponentEndpoint, body })
                const newLocation = response?.headers?.location

                const newItemResponse = await doGet({ endpoint: newLocation })
                updateTarget(newItemResponse?.data?.id)
            } else {

                await doPut({ endpoint: updateOpponentEndpoint, body })

            }


        } catch (error) {
            console.error(error)
        }
        doNavigate(opponentsRoute)
        setSnackbarLoading(false)
    }

    const onCancel = async () => {
        if (!isCreatingNew) {
            await getData()
        }
        doNavigate(opponentsRoute)
    }

    useEffect(() => {
        
        if (clubId && !isCreatingNew) {
            getData()
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    return <BasePageComponent
        pageTitle={"Opponents"}
        backRoute={opponentsRoute}
        inlineContent={<ButtonStack saveAction={onSubmit} cancelAction={onCancel} />}
        snackbarLoading={snackbarLoading}
    >

        <CardContainer padding="20px">
            <Grid container rowSpacing={5} columns={2}>
                <Grid item xs={2}>
                    <FormHeader
                        label={"Club Name"}
                        title={name}
                        setTitle={setName}
                        description={description}
                        setDescription={setDescription}
                        minioId={imageId}
                        setMinioId={setImageId}
                        setFormData={setImageFormData}
                        readOnly={!canEdit}
                    />
                </Grid>
                <Grid item xs={2}>
                    <GoogleMapsField label="Location" value={location} setValue={setLocation} readOnly={!canEdit} />
                </Grid>

                <Grid item xs={2} sm={1}>
                    <FormField label={"Club Contact Number"} value={clubContactNumber} setValue={setClubContactNumber} readOnly={!canEdit} />
                </Grid>
                <Grid item xs={2} sm={1}>
                    <FormField label={"Club Email Address"} value={clubContactEmail} setValue={setClubContactEmail} readOnly={!canEdit} />
                </Grid>
                <Grid item xs={2}>
                    <FormField label={"Contact Person"} value={contactPersonName} setValue={setContactPersonName} readOnly={!canEdit} />
                </Grid>
                <Grid item xs={2} sm={1}>
                    <FormField label={"Contact Person Number"} value={contactPersonNumber} setValue={setContactPersonNumber} readOnly={!canEdit} />
                </Grid>
                <Grid item xs={2} sm={1}>
                    <FormField label={"Contact Person Email Address"} value={contactPersonEmail} setValue={setContactPersonEmail} readOnly={!canEdit} />
                </Grid>
            </Grid>

        </CardContainer>

    </BasePageComponent>


}

export default EditOpponentPage