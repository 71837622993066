import { Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { addIndex, has, is, keys, map, reduce, sort } from "ramda"
import dayjs from "dayjs"
import getMemberTypeName from "../../utils/helper-functions/get-member-type-name"
import getActivityStatusCounts from "../../utils/helper-functions/get-activity-status-counts"
import { statusPending, statusAccepted, statusActive, statusAssigned, statusInactive, statusList, statusVacant } from "../../utils/constants"
import StatusCircleComponent from "../status-circle-component"


export const columnTypeEmail = "email"
export const columnTypeString = "string"
export const columnTypePhone = "phone"
export const columnTypeDate = "date"
export const columnTypeMemberType = "members_type"
export const columnTypeAccountStatus = "account_status"
export const columnTypeStatus = "status"
export const columnTypeActivities = "activities"
export const columnTypeLocation = "location"
export const columnTypeDuration = "duration"
export const columnTypeFloat = "float"
export const columnTypeBoolean = "boolean"
export const columnTypeDocument = "document"
export const columnTypeDropdown = "dropdown"
export const columnTypeActions = "actions"
export const columnTypeExpiry = "expiry"
export const columnTypeAssign = "assign"

const TypedTableCell = ({ itemKey, type, item }) => {
    const value = item[itemKey]

    let innerComponent

    switch (type) {
        case columnTypeDate:
            innerComponent = value ? dayjs(value).format("D MMMM YYYY") : "N/A"
            break
        case columnTypeMemberType:
            innerComponent = getMemberTypeName(value)
            break
        case columnTypeAccountStatus:
            switch (value) {
                case "ACTIVE":
                    innerComponent = <Stack direction = "row" spacing={1}>
                        <StatusCircleComponent status={statusActive} />
                        <Typography>Active</Typography>
                    </Stack>
                break;

                case "INACTIVE":
                    innerComponent = <Stack direction = "row" spacing={1}>
                        <StatusCircleComponent status={statusInactive} />
                        <Typography>Inactive</Typography>
                    </Stack>
                break;

                case "EMAIL_EXPIRED":
                case "EMAIL_PENDING":
                    innerComponent = <Stack direction = "row" spacing={1}>
                        <StatusCircleComponent status={statusPending} />
                        <Typography>Pending</Typography>
                    </Stack>
                break;
                default:
                    break;
            }

            break;
        case columnTypeStatus:
            const countsStatus = getActivityStatusCounts(value)

            let itemStatus = statusAccepted
            for (let statusIndex in statusList) {
                const status = statusList[statusIndex]
                if (has(status, countsStatus)) {
                    itemStatus = status
                    break
                }
            }

            innerComponent = <StatusCircleComponent status={itemStatus} />
            break
        case columnTypeActivities:
            const countsActivities = getActivityStatusCounts(value)
            innerComponent = <Stack direction="row" spacing={1}>
                <StatusCircleComponent status={statusVacant} count={countsActivities[statusVacant] ?? 0} />
                <StatusCircleComponent status={statusAssigned} count={countsActivities[statusAssigned] ?? 0} />
                <StatusCircleComponent status={statusAccepted} count={countsActivities[statusAccepted] ?? 0} />
            </Stack>
            break
        case columnTypeLocation:
            innerComponent = value?.description ?? "N/A"
            break
        case columnTypeString:
        default:
            innerComponent = value ?? "N/A"
    }

    return <TableCell sx={{ maxWidth:"20ch", color: '#323E59', lineHeight: "17.07px" }}>{innerComponent}</TableCell>
}

const TypedTableGroup = ({ group, type }) => {


    let innerComponent = group

    switch (type) {
        case columnTypeDate:
            innerComponent = dayjs(group).format("dddd, MMM D")
            break
        case columnTypeString:
        default:
            break
    }

    return <TableRow>
        <TableCell colSpan={100} width={"100%"}>

            <Typography
                fontSize={"14px"}
                fontWeight={600}
                lineHeight={"17px"}
                color={'#3C3352'}
                letterSpacing={"1.86px"}
                fontFamily={"Montserrat"}
                textTransform={"uppercase"}
            >
                {innerComponent}
            </Typography>

        </TableCell>
    </TableRow>

}


const TableComponent = ({ 
    columns, 
    items, 
    onRowClick, 
    groupBy, 
    groupByColumnType, 
    loading, 
    error,
    groupSort = (a,b) => (a??"").localeCompare(b??"")
}) => {


    const tableHeaders = map(({ key, title }) => <TableCell key={key}>
        <Typography
            fontSize={"14px"}
            fontWeight={600}
            lineHeight={"17px"}
            color={'#3C3352'}
            letterSpacing={"1.86px"}
            fontFamily={"Montserrat"}
            textTransform={"uppercase"}
        >
            {title}
        </Typography>
    </TableCell>)(columns)


    const groupedItems = groupBy ? reduce((acc, item) => {

        const group = item[groupBy]

        if (has(group, acc)) {
            acc[group] = [...acc[group], item]
        } else {
            acc[group] = [item]
        }
        return acc

    }, {}, items) : ({})

    const sortedGroups = groupBy ? sort(groupSort, keys(groupedItems)) : []


    const itemList = groupBy ?

        reduce((acc, key) => {

            acc = [...acc, key, ...groupedItems[key] ?? []]
            return acc

        }, [], sortedGroups)

        : items

    

    return <TableContainer sx={{
        background: "white",
        borderRadius: "4px",
    }}>
        <Table>
            <TableHead sx={{
                background: '#C4C4C4'
            }}>
                <TableRow>
                    {tableHeaders}
                </TableRow>
            </TableHead>
            
            {!error && !loading &&
            <TableBody>
                {addIndex(map)((item, index) => {
                    if (!is(Object, item)) {
                        return <TypedTableGroup key={index} group={item} type={groupByColumnType} />
                    } else {
                        return <TableRow
                            key={index}
                            onClick={() => {
                                if (onRowClick) {
                                    onRowClick(item)
                                }
                            }}
                            sx={
                                onRowClick && {
                                    cursor: "pointer",
                                    ":hover": {
                                        background: "#0000000F"
                                    },
                                    ":active": {
                                        background: "#00000022"
                                    }
                                }
                            }
                        >

                            {map(({ key, type }) =>
                                <TypedTableCell
                                    key={key}
                                    itemKey={key}
                                    type={type}
                                    item={item}
                                />)(columns)}

                        </TableRow>
                    }
                })(itemList)}
            </TableBody>}
        </Table>
        {loading && <Skeleton width={"100%"} height={100} variant="rectangular"/>}
        {!loading && error && <Stack height={100} width={"100%"} justifyContent={"center"} alignItems={"center"} direction={"column"}>
                <Typography textAlign={"center"}>Something went wrong! Please try again later.</Typography>
                <Typography sx={{opacity:0.5}} textAlign={"center"}>(Error code {error})</Typography>
            </Stack>}
    </TableContainer>

}

export default TableComponent