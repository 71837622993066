import { Grid, Stack, Typography } from "@mui/material"
import { WeekHeader } from "./day-header"
import DayBox from "./day-box"
import { equals, forEach, has, map, range } from "ramda"
import dayjs from "dayjs"
import { dateFromDateTime, formatTime, timeFromDateTime } from "../../utils/helper-functions/datetime-functions"
import StatusCircleComponent from "../../components/status-circle-component"
import { newFormEntityId, statusAccepted, statusAssigned, statusVacant } from "../../utils/constants"
import getActivityStatusCounts, { getEventStatus } from "../../utils/helper-functions/get-activity-status-counts"
import { editEventRoute } from "../../utils/page-routes"
import { useState } from "react"
import { useIsAdmin } from "../../utils/user-hooks"
import useDoNewTab from "../../utils/do-new-tab"

const EventsListBox = ({ events, date }) => {

    const doNewTab = useDoNewTab()
    const isAdmin = useIsAdmin()

    const [hovered, setHovered] = useState(false)
    const [eventHovered, setEventHovered] = useState(false)

    return <Grid
        item
        width={"100%"}
        height={'500px'} // Placeholder
        xs={1}
        onMouseEnter={() => {
            setHovered(true)
        }}
        onMouseLeave={() => {
            setHovered(false)
        }}
        onClick={() => {
            if (isAdmin && !eventHovered) {
                doNewTab(editEventRoute, {target: newFormEntityId, date:dayjs(date).toISOString()})
            }
        }}
        sx={{
            textAlign: 'center',
            border: "solid 1px #F1F1F1",
            borderColor: "#F1F1F1",
            textTransform: "uppercase",
            paddingTop: "10px",
            color: "#595959",
            fontSize: "20px",
            fontWeight: 500,
            overflow: 'auto',
            ":hover": {
                background: isAdmin && "#FAFAFA",
                cursor: isAdmin && "pointer"
            }
        }}
    >
        <Stack direction="column" position="relative">
            {map((event) => {
                const eventCounts = getActivityStatusCounts(event?.activities ?? [])
                return <Stack
                    marginX={'4px'}
                    marginY={'2px'}
                    padding={'4px'}
                    key={event?.id}
                    direction="row"
                    bgcolor={"#F1F1F1"}
                    justifyContent={"space-between"}
                    onMouseEnter={() => {
                        setEventHovered(true)
                    }}
                    onMouseLeave={() => {
                        setEventHovered(false)
                    }}
                    onClick={() => {
                        doNewTab(editEventRoute, {target: event?.id})
                    }}
                    sx={{
                        ":hover": {
                            background: "#D1D1D1",
                            cursor: "pointer"
                        }
                    }}
                >
                    <Stack direction="column">
                        <Typography textAlign={"start"} fontWeight={700}>
                            {formatTime(timeFromDateTime(event?.start))}
                        </Typography>
                        <Typography fontWeight={"14px"} textTransform={"none"} textAlign={"start"}>
                            {event?.eventTypeName}
                        </Typography>
                        <Typography fontWeight={"14px"} textTransform={"none"} textAlign={"start"}>
                            {event?.details?.name}
                        </Typography>

                    </Stack>

                    <Stack direction={"column"} spacing={"2px"} position="absolute" right="10px">
                        {eventCounts[statusAccepted] > 0 && <StatusCircleComponent size={"28px"} status={statusAccepted} count={eventCounts[statusAccepted]} />}
                        {eventCounts[statusAssigned] > 0 && <StatusCircleComponent size={"28px"} status={statusAssigned} count={eventCounts[statusAssigned]} />}
                        {eventCounts[statusVacant] > 0 && <StatusCircleComponent size={"28px"} status={statusVacant} count={eventCounts[statusVacant]} />}

                    </Stack>


                </Stack>
            })(events)}
            <div style={{
            textAlign: "center",
            fontSize: "30pt",
            color:"#B0B0B0",
            visibility: hovered ? "visible" : "hidden"
        }}>
            +
        </div>
        </Stack>

    </Grid>

}

const WeekEventView = ({ selectedDate, events, onDayClick, onDotClick }) => {

    const startOfWeek = selectedDate.startOf('week')
    const endOfWeek = selectedDate.endOf('week')

    const dayCount = endOfWeek.diff(startOfWeek, 'day')
    const dates = map((days) => startOfWeek.add(days, 'day'))(range(0, dayCount + 1))


    let eventsPerDate = {}
    let countsPerDate = {}

    forEach((date) => {
        const formattedDate = date.format("YYYY-MM-DD")

        eventsPerDate[formattedDate] = []
        countsPerDate[formattedDate] = {
            [statusAssigned]: 0,
            [statusVacant]: 0,
            [statusAccepted]: 0
        }
        forEach((event) => {
            const eventDate = dateFromDateTime(event?.start)
            if (equals(eventDate?.format("YYYY-MM-DD"))(formattedDate)) {
                eventsPerDate[formattedDate] = [...eventsPerDate[formattedDate], event]

                const eventStatus = getEventStatus(event)
                if (has(eventStatus, countsPerDate[formattedDate])) {
                    countsPerDate[formattedDate][eventStatus] += 1
                } else {
                    countsPerDate[formattedDate][eventStatus] = 1
                }



            }
        })(events)

    })(dates)


    return <Grid
        container
        columns={7}
        width={"full"}
        justifyContent={"space-evenly"}
    >

        <WeekHeader />
        {map((date) => <DayBox onDotClick={onDotClick} key={date.toISOString()} noMonthHighlight onClick={onDayClick} counts={countsPerDate[dayjs(date).format("YYYY-MM-DD")]} date={date} selectedDate={selectedDate} />)(dates)}
        {map((date) => {
            const formattedDate = dayjs(date).format("YYYY-MM-DD")
            return <EventsListBox key={date.toISOString()} date={date} selectedDate={selectedDate} events={eventsPerDate[formattedDate]} />
        })(dates)}

    </Grid>


}

export default WeekEventView