import { FormControlLabel, Stack, Checkbox } from "@mui/material"
import BasePageComponent from "../../components/base-page-component"
import CardContainer from "../../components/card-container"
import ObjectListComponent from "../../components/object-list-component"
import FormHeader from "../../components/form-header-component"
import FormTableComponent from "../../components/form-table-component"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useApiUtilsContext } from "../../providers/api-utils-provider"
import { selectCurrentClubId } from "../../redux/selectors"
import { apiPathCreateEventType, apiPathGetActivityTypes, apiPathGetEventType, apiPathGetEventTypes } from "../../utils/endpoint-paths"
import { equals, find, findIndex, head, map, reject, update } from "ramda"
import { newFormEntityId } from "../../utils/constants"
import SaveCancelAddStack from "../../components/buttons/save-cancel-add-stack"
import { isNullOrUndefinedOrEmpty } from "../../utils/helper-functions/is-null-or-undefined-or-empty"
import { blue } from "@mui/material/colors"
import useDebounce from "../../utils/use-debounce"
import { useSearchParams } from "react-router-dom"


const EventTypesPage = () => {

    const [eventTypeList, setEventTypeList] = useState([])
    const [eventTypeInput, setEventTypeInput] = useState("")
    const [eventTypeSearch, setEventTypeSearch] = useState("")

    const currentClubId = useSelector(selectCurrentClubId)

    const { generateEndpoint, doGet, doPost, doPut, uploadPfp } = useApiUtilsContext()


    const [searchParams, setSearchParams] = useSearchParams()

    


    const currentEventTypeId  = searchParams.get("target") ?? undefined
    const setCurrentEventTypeId = (id) => setSearchParams({target: id})


    

    const isCreatingNew = equals(currentEventTypeId, newFormEntityId)


    const [activities, setActivities] = useState([])

    const getEventTypesEndpoint = generateEndpoint(apiPathGetEventTypes(currentClubId))
    const createEventTypeEndpoint = generateEndpoint(apiPathCreateEventType(currentClubId))
    const updateEventTypeEndpoint = generateEndpoint(apiPathGetEventType(currentEventTypeId))
    const getActivitiesListEndpoint = generateEndpoint(apiPathGetActivityTypes(currentClubId))
    

    const [loading, setLoading] = useState(false)


    const mapActivities = (qual) => {
        return {
            label: qual?.details?.name,
            id: qual?.id
        }
    }

    const disabled = currentEventTypeId === undefined

    const [currentEventTypeName, setCurrentEventTypeName] = useState()
    const [currentEventTypeDescription, setCurrentEventTypeDescription] = useState()
    const [currentEventTypeActivities, setCurrentEventTypeActivities] = useState([])
    const [currentEventTypeImageId, setCurrentEventTypeImageId] = useState()
    const [currentEventTypeOpponent, setCurrentEventTypeOpponent] = useState(true)
    const [imageFormData, setImageFormData] = useState()

    const mappedActivities = map(mapActivities)((activities))

    const currentEventTypeIndex = findIndex((eventType) => `${eventType.id}` === `${currentEventTypeId}`)(eventTypeList)



    const getActivityById = id => find((qual) => equals(qual?.id)(id))(activities)

    const updateCurrentEventType = (newEventType) => {
        //update the current details to new details
        setEventTypeList((prev) => update(currentEventTypeIndex, newEventType, prev));
        setCurrentEventTypeId(newEventType?.id);
    };

    const addNewEventType = () => {
        setCurrentEventTypeId(newFormEntityId);
        setEventTypeList((prev) => [
            { id: newFormEntityId },
            ...prev
        ])
    }

    const onSubmit = async () => {
        setLoading(true)
        try {

            let pictureId = isCreatingNew ?  undefined :  currentEventTypeImageId
            if (imageFormData) {
                pictureId = await uploadPfp(imageFormData)
            }

            const body = {
                details: {
                    name: currentEventTypeName,
                    description: currentEventTypeDescription,
                    pictureId
                },
                activities: currentEventTypeActivities,
                hasOpponent: currentEventTypeOpponent
            }

            if (isCreatingNew) {


                const response = await doPost({
                    endpoint: createEventTypeEndpoint,
                    body: body
                });

                const newEventTypeResponse = await doGet({
                    endpoint: response?.headers?.location
                });

                //Set new stuff
                updateCurrentEventType(newEventTypeResponse.data);
            } else {

                await doPut({
                    endpoint: updateEventTypeEndpoint,
                    body: body
                });
                //Set new stuff
                updateCurrentEventType({ id: currentEventTypeId, ...body });
            }
        } catch (error) {
            console.error(error)
        }
        setLoading(false)
    }

    const onCancel = () => {
        if (isCreatingNew) {
            const newEvents = reject((eventType) => equals(eventType?.id)(newFormEntityId))(eventTypeList)
            setEventTypeList(newEvents)
            setCurrentEventTypeId(head(newEvents)?.id)
        } else {
            setCurrentEventTypeName(eventTypeList[currentEventTypeIndex]?.details?.name)
            setCurrentEventTypeDescription(eventTypeList[currentEventTypeIndex]?.details?.description)
            setCurrentEventTypeActivities(eventTypeList[currentEventTypeIndex]?.activities ?? [])
            setCurrentEventTypeImageId(eventTypeList[currentEventTypeIndex]?.details?.pictureId ?? null)
            setCurrentEventTypeOpponent(eventTypeList[currentEventTypeIndex]?.hasOpponent ?? null)
        }
    }

    const onAddActivitiy = (activity) => {
        const { id: activityId } = activity
        const activitiy = getActivityById(activityId)
        setCurrentEventTypeActivities((prev) => [...prev, activitiy])
    }

    const onRemoveActivity = (activity) => {
        const { id: activityId } = activity
        setCurrentEventTypeActivities((prev) => reject((a) => equals(a?.id)(activityId))(prev))
    }


    useEffect(() => {
        if (currentEventTypeId) {
            setCurrentEventTypeName(eventTypeList[currentEventTypeIndex]?.details?.name)
            setCurrentEventTypeDescription(eventTypeList[currentEventTypeIndex]?.details?.description)
            setCurrentEventTypeActivities(eventTypeList[currentEventTypeIndex]?.activities ?? [])
            setCurrentEventTypeImageId(eventTypeList[currentEventTypeIndex]?.details?.pictureId)
            setCurrentEventTypeOpponent(eventTypeList[currentEventTypeIndex]?.hasOpponent ?? true)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEventTypeId])

    useDebounce(() => {
        setEventTypeSearch(eventTypeInput)
    }, 500, [eventTypeInput])


    useEffect(() => {
        const getEventTypesAndActivities = async () => {
            const queryParams = {
                search_text: eventTypeSearch?.length > 0 ? eventTypeSearch : undefined
            }

            const eventTypeResponse = await doGet({ endpoint: getEventTypesEndpoint, queryParams })
            setEventTypeList(eventTypeResponse?.data ?? [])

            const activityResponse = await doGet({ endpoint: getActivitiesListEndpoint })
            setActivities(activityResponse?.data ?? [])

        }


        if (currentClubId) {
            getEventTypesAndActivities()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentClubId, eventTypeSearch])


    return <BasePageComponent pageTitle={"Event Types"}
        snackbarLoading={loading}
        marginRight="60px"
        inlineContent={
            <SaveCancelAddStack
                addLabel="Add New Event Type"
                addAction={addNewEventType}
                saveAction={onSubmit}
                cancelAction={onCancel}
                disableSave={disabled ||
                    isNullOrUndefinedOrEmpty(currentEventTypeName) ||
                    isNullOrUndefinedOrEmpty(currentEventTypeDescription)
                }
                disableCancel={disabled}
                disableAdd={currentEventTypeId === newFormEntityId}
            />}>
        <Stack direction="row" spacing={5} padding={5}>
            <ObjectListComponent
                title={"Event Type"}
                searchPlaceholder={"Search for Event Type..."}
                items={eventTypeList}
                getItemName={(eventType) => eventType?.details?.name ?? "New Event Type"}
                onSelectItem={(eventType) => setCurrentEventTypeId(eventType?.id)}
                selectedKey={currentEventTypeId}
                searchValue={eventTypeInput}
                setSearchValue={setEventTypeInput}
            />

            <CardContainer padding={"20px 30px 50px 30px"}>
                <FormHeader
                    label={"Event Type Name"}
                    setTitle={setCurrentEventTypeName}
                    title={currentEventTypeName ?? ""}
                    setDescription={setCurrentEventTypeDescription}
                    description={currentEventTypeDescription ?? ""}
                    disabled={disabled}
                    minioId={currentEventTypeImageId}
                    setFormData={setImageFormData}
                    setMinioId={setCurrentEventTypeImageId}
                    mandatory
                    extraOptions={
                        <FormControlLabel 
                            control={
                                <Checkbox 
                                    checked={currentEventTypeOpponent}
                                    onChange={(event) => setCurrentEventTypeOpponent(event.target.checked)} 
                                    sx={{
                                        color: blue[500], 
                                        '&.Mui-checked': {
                                            color: blue[500],
                                        }
                                    }}
                                    disabled={disabled} 
                                />} 
                            label="Opponents Required" 
                        />
                    }
                />


                <FormTableComponent
                    disabled={disabled}
                    columns={[
                        { title: "Activity Type", getValue: (row) => row?.details?.name },
                        { title: "Description", getValue: (row) => row?.details?.description },
                        { title: "Multiplier", getValue: (row) => Number.parseFloat(row?.multiplier).toFixed(2) }
                    ]}
                    items={currentEventTypeActivities ?? []}
                    onAdd={onAddActivitiy}
                    onRemove={onRemoveActivity}
                    searchItems={mappedActivities}
                    itemEquality={(searchItem, rowItem) => false}
                />
            </CardContainer>

        </Stack>


    </BasePageComponent>


}

export default EventTypesPage