import { Checkbox, FormControlLabel, Stack, Typography, Slider } from "@mui/material"
import BasePageComponent from "../../components/base-page-component"
import ButtonComponent from "../../components/button-component"
import { Add } from "@mui/icons-material"
import { Link, useSearchParams } from "react-router-dom"
import { addMembersRoute } from "../../utils/page-routes"
import TableComponent, { columnTypeDate, columnTypeEmail, columnTypeMemberType, columnTypePhone, columnTypeAccountStatus, columnTypeString } from "../../components/table-component"
import { useApiUtilsContext } from "../../providers/api-utils-provider"
import { apiPathClubMembers, apiPathClubTeamsList, apiPathGetQualificationTypeList } from "../../utils/endpoint-paths"
import { useEffect, useState } from "react"
import { compose, isEmpty, map, reject, values } from "ramda"
import { editMembersRoute } from "../../utils/page-routes"
import { useSelector } from "react-redux"
import { selectCurrentClubId } from "../../redux/selectors"
import { useIsAdmin } from "../../utils/user-hooks"
import DropdownFilterField, { createFilterParams, filterAll } from "../../components/fields/dropdown-filter-field"
import { genderDropdownValues } from "../../components/fields/gender-field"
import { memberTypeDropdownOptions } from "../../utils/constants"
import isAllMembersTeam from "../../utils/helper-functions/is-all-members-team"
import SearchBarComponent from "../../components/search-bar-component"
import useDebounce from "../../utils/use-debounce"
import useDoNavigate from "../../utils/do-navigate"
import { sortTeamMembersExclusive } from "../../utils/helper-functions/sort-team-members"

const MembersPage = () => {
    
    const minAge = 0
    const maxAge = 100
    
    const doNavigate = useDoNavigate()
    const { doGet, generateEndpoint } = useApiUtilsContext()

    const clubId = useSelector(selectCurrentClubId)


    const [searchParams, setSearchParams] = useSearchParams()

    const getMembersEndpoint = generateEndpoint(apiPathClubMembers(clubId))
    const getTeamsEndpoint = generateEndpoint(apiPathClubTeamsList(clubId))
    const getQualsEndpoint = generateEndpoint(apiPathGetQualificationTypeList(clubId))

    const [items, setItems] = useState([])
    const [teamFilterList, setTeamFilterList] = useState([])
    const [qualFilterList, setQualFilterList] = useState([])

    const isAdmin = useIsAdmin()

    const [teamFilter, setTeamFilter] = useState(filterAll)
    const [genderFilter, setGenderFilter] = useState(filterAll)
    const [memberTypeFilter, setMemberTypeFilter] = useState(filterAll)
    const [qualFilter, setQualFilter] = useState(filterAll)
    const [archivedFilter, setArchivedFilter] = useState(false)
    const [ageSliderValue, setAgeSliderValue] = useState([minAge,maxAge])
    const [ageFilter, setAgeFilter] = useState([minAge, maxAge])

    const [displaySearch, setDisplaySearch] = useState("")
    const [searchFilter, setSearchFilter] = useState()


    useDebounce(() => {
        if(displaySearch.length > 0) {
            setSearchFilter(displaySearch)
        } else {
            setSearchFilter(undefined)
        }
    }, 500, [displaySearch])

    const minAgeFilter = ageFilter[0] === minAge ? filterAll : ageFilter[0]
    const maxAgeFilter = ageFilter[1] === maxAge ? filterAll : ageFilter[1]


    useDebounce(() => {
        setAgeFilter(ageSliderValue)
    }, 500, [...ageSliderValue])

    const filters = {
        "search_text": searchFilter,
        "gender": genderFilter,
        "qualification_type": qualFilter,
        "user_types": memberTypeFilter,
        "show_archived": archivedFilter,
        "team": teamFilter,
        "min_age": minAgeFilter,
        "max_age": maxAgeFilter
    }


    const filterParams = createFilterParams(filters)

    const mapMembers = (data) => {
        return {
            accountStatus: data?.accountStatus,
            uuid: data?.member?.uuid,
            email: data?.member?.email,
            firstName: data?.member?.firstName,
            lastName: data?.member?.lastName,
            mobile: data?.member?.mobile,
            dateOfBirth: data?.member?.dateOfBirth,
            role: data?.userTypes[0],
            search_text: isEmpty(searchFilter) ? undefined : searchFilter
        }
    }

    const mapTeamList = (data) => compose(
        map(mapTeam),
        reject(isAllMembersTeam)
    )(data ?? [])

    const mapTeam = (data) => ({
        value: data?.id,
        label: data?.details?.name
    })

    const mapQual = (data) => ({
        value: data?.id,
        label: data?.details?.name
    })

    useEffect(() => {
        const memberFilter = searchParams.get("userTypes")
        setMemberTypeFilter(memberFilter)
        setSearchParams(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {
        const getTeams = async () => {
            if (clubId) {
                try {
                    const response = await doGet({ endpoint: getTeamsEndpoint })
                    setTeamFilterList(mapTeamList(response?.data))
                } catch (error) {
                    console.error(error)
                }

            }
        }

        const getQuals = async () => {
            if (clubId) {
                try {
                    const response = await doGet({ endpoint: getQualsEndpoint })
                    setQualFilterList(map(mapQual)(response?.data ?? []))
                } catch (error) {
                    console.error(error)
                }
            }
        }


        getTeams()
        getQuals()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clubId])

    useEffect(() => {

        const getMembers = async () => {

            if (clubId) {
                try {
                    const response = await doGet({ endpoint: getMembersEndpoint, queryParams: filterParams })
                    setItems(map(mapMembers)(sortTeamMembersExclusive(response?.data ?? [])))
                } catch (error) {
                    console.error(error)
                }

            }
        }



        getMembers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clubId, doGet, getMembersEndpoint, ...values(filters)])

    const columns = (() => {
        const userCols = [
                { key: "email", title: "Email", type: columnTypeEmail },
                { key: "firstName", title: "First Name", type: columnTypeString },
                { key: "lastName", title: "Last Name", type: columnTypeString },
                { key: "mobile", title: "Ph. Number", type: columnTypePhone },
                { key: "role", title: "Member Type", type: columnTypeMemberType },
                { key: "dateOfBirth", title: "Dob", type: columnTypeDate }
            ]

        if (!isAdmin)
            return userCols
        const adminCols = [
                { key: "accountStatus", title: "Status", type: columnTypeAccountStatus}
            ]
        return [...userCols, ...adminCols]
    })()



    return <BasePageComponent pageTitle={"Members"} 
    inlineJustify="flex-start"
    inlineContent={<SearchBarComponent placeholder="Search for Member Name, Email..." searchValue={displaySearch} setSearchValue={setDisplaySearch}/>}
    width="100%"
    >
        <Stack direction={"column"} sx={{
            marginX: "30px"
        }}>
            <Stack direction={"row"} marginY={"20px"} alignItems={"center"} justifyContent={"space-between"}>
                <Stack direction={"row"} columnGap={2}>
                    <DropdownFilterField
                        placeholder={"Teams"}
                        value={teamFilter}
                        setValue={setTeamFilter}
                        options={teamFilterList}
                    />
                    <DropdownFilterField
                        placeholder={"Genders"}
                        options={genderDropdownValues}
                        value={genderFilter}
                        setValue={setGenderFilter}
                    />
                    <DropdownFilterField
                        placeholder={"Member Types"}
                        options={memberTypeDropdownOptions}
                        value={memberTypeFilter}
                        setValue={setMemberTypeFilter}
                    />
                    <DropdownFilterField
                        placeholder={"Qualifications"}
                        value={qualFilter}
                        options={qualFilterList}
                        setValue={setQualFilter}
                    />
                    <Stack width="11ch" marginX="10px">

                        <Typography>
                            Age: {ageSliderValue[0]} - {ageSliderValue[1]}
                        </Typography>
                        
                        <Slider
                            size="small"
                            valueLabelDisplay="auto"
                            value={ageSliderValue}
                            onChange={(event, newValue) => setAgeSliderValue(newValue)}
                        />
                    </Stack>
                    {isAdmin && <FormControlLabel
                        sx={{
                            marginRight: "2px",
                            marginLeft:"2px"
                        }}
                        value={archivedFilter}
                        onChange={() => setArchivedFilter(!archivedFilter)}
                        control={<Checkbox/>} label="Show Inactive" />}
                </Stack>

                {isAdmin &&
                    <ButtonComponent
                        title="Add New Members"
                        icon={<Add />}
                        component={Link}
                        to={addMembersRoute}
                    />
                }
            </Stack>
            <TableComponent
                items={items ?? []}
                onRowClick={(item) => {
                    doNavigate(editMembersRoute, {target: item.uuid})
                }}
                columns={columns}
            />
        </Stack>

    </BasePageComponent>


}

export default MembersPage